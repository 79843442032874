import React, { useEffect, useState } from 'react';
import { useSocket } from "use-socketio";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommentIcon from '@material-ui/icons/Comment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import {api} from "./config";
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '@material-ui/lab/Pagination';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {withStyles} from "@material-ui/core/styles";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import ReplayIcon from '@material-ui/icons/Replay';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value] 
  );

  return debouncedValue;
}

const MetaBox = withStyles(({breakpoints}) => ({
    root: {
        [breakpoints.down('md')]: {
            maxWidth: 'inherit',
            fontSize: '10px'
        },
        [breakpoints.up('md')]: {
            maxWidth: '30%',
        },
    }
}))(Box);

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),    
    },
    right: {
        right: theme.spacing(2)
    },
    left: {
        left: theme.spacing(2)
    }
}));
const News = () => {
    const [scoops, setScoops] = useState([]);
    const [pages, setPages] = useState(null)
    const [time, setTime] = useState(60);
    const [sort, setSort] = useState('date');
    const [orientation, setOrientation] = useState('left');
    const [sortDirection, setSortDirection] = useState('desc');
    const [threshold, setThreshold] = useState(15000);
    const debouncedThreshold = useDebounce(threshold, 1000);
    const [timer, setTimer] = useState(0);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [showThresholdSlider, setShowThresholdSlider] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState({});
    const [date, setDate] = useState(new Date().toLocaleDateString('en-CA'));
    const classes = useStyles();
    const toggleThresholdSlider = () => {
        setShowThresholdSlider(!showThresholdSlider);
    }

    const handleSliderChange = (event, newValue) => {
      setThreshold(newValue);
    };
  
    const handleInputChange = (event) => {
      setThreshold(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlur = () => {
      if (threshold < 0) {
        setThreshold(0);
      }
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    const loadScoops = () => {
        setLoading(true);
        fetch(`${api}/scoops?sort=${sort}&direction=${sortDirection}&viewsThreshold=${threshold}&page=${page-1}&date=${date}`)
            .then(res => res.json())
            .then(res => {
                try{
                    const totalItems = parseInt(res[0].full_count);
                    setPages(Math.floor(totalItems/10));
                    setTotalItems(totalItems);
                }catch(e){
                    console.log(e);
                    setTotalItems(0);
                    setPages(null);
                }
                setScoops(res)
            })
            .finally(()=>{
                setLoading(false);
            })
    };

    useEffect(() => {
        loadScoops();
    }, [sort, sortDirection, debouncedThreshold, page, date]);
    const reload = () => {
        loadScoops();
    }
    useSocket("timer", timer => {
        setTimer(((time - timer)*100/time));
    })
    useSocket("new", scoop => {
        loadScoops();
    })

    const onSort = columnName => () =>{
        setSort(columnName);
        if(sort === columnName){
            setSortDirection(sortDirection === "desc" ? "asc" : "desc");
        }else{
            setSortDirection("desc");
        }
    }
    const markScoopAsRead = scoop_id => async () => {
        setDeleting({[scoop_id]: true});
        await fetch(`${api}/markasread?id=${scoop_id}`);
        setScoops(scoops.filter(scoop => scoop.id !== scoop_id));
    }
    const formatDate = dateStr => {
        const date = new Date(dateStr)
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric' }) 
        const [{ value: month },,{ value: day },,{ value: year },,{ value: hour },,{ value: minute }] = dateTimeFormat.formatToParts(date) 

        return `${day}/${month}/${year } ${hour}:${minute}`;
    }
    const sortArrow = () => {
        return sortDirection === 'desc' ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />
    }
    const controlButton = (column, IconComponent) => {
        return <>
            <IconButton onClick={onSort(column)}>
                <IconComponent fontSize="large" />
            </IconButton>
            {sort === column && sortArrow()}
        </>
    }
    const changeOrientation = () => {
        setOrientation(orientation === 'left' ? 'right' : 'left')
    }
    const onDateChange = e => {
        const value = e.target.value;
        setDate(value);
    }
    return (
        <div className="App">
            <LinearProgress variant="determinate" value={timer} />
            <AppBar position="sticky">
                <Toolbar>
                    {controlButton('date', ScheduleIcon)}
                    {controlButton('comments', CommentIcon)}
                    {controlButton('views', VisibilityIcon)}
                    <Box flexGrow={1} />
                    <IconButton onClick={changeOrientation}>
                        {orientation === 'left' ? <ArrowBackIcon fontSize="large" /> : <ArrowForwardIcon fontSize="large" /> }
                    </IconButton>          
                    <IconButton onClick={toggleThresholdSlider}>
                        <Badge badgeContent={totalItems} color="primary">
                            <TrackChangesIcon fontSize="large" />
                        </Badge>
                    </IconButton>
                </Toolbar>
            
            {showThresholdSlider && <Box paddingTop={2} paddingBottom={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Slider
                            value={typeof threshold === 'number' ? threshold : 0}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            max={20000}
                            step={1000}
                            color="secondary"
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            value={threshold}
                            margin="dense"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 1000,
                                min: 0,
                                max: 20000,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid>
                <TextField
                    fullWidth
                    onChange={onDateChange}
                    value={date}
                    type="date"
                />
                </Grid>
            </Box>}
            </AppBar>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        {scoops.map((scoop) => {
                            const DeleteButton = <TableCell component="th" scope="row">
                                {deleting[scoop.id] ? <CircularProgress /> :  <IconButton aria-label="delete" onClick={markScoopAsRead(scoop.id)}>
                                    <DeleteIcon fontSize="large" />
                                </IconButton>}
                            </TableCell> ;
                            return <TableRow key={scoop.id}>
                                {orientation === 'left' && DeleteButton}
                                <TableCell align="right" >
                                    <MetaBox display="flex" alignItems="center" justifyContent="space-around">
                                        <Box display="flex" alignItems="center"><ScheduleIcon fontSize="small" />&nbsp;{formatDate(scoop.date)}</Box>
                                        <Box display="flex" alignItems="center"><CommentIcon fontSize="small" />&nbsp;{scoop.comments}</Box>
                                        <Box display="flex" alignItems="center"><VisibilityIcon fontSize="small" />&nbsp;{scoop.views}</Box>
                                        <Box display="flex" alignItems="center"><FingerprintIcon fontSize="small" />&nbsp;{scoop.id}</Box>
                                    </MetaBox>
                                    <Box dir="rtl" fontSize={26}>
                                        <Link color="secondary" target="_blank" rel="noopener noreferrer" href={`${api}/scoop/${scoop.scoop_id}`}>{scoop.text}</Link>
                                    </Box>
                                </TableCell>
                                {orientation === 'right' && DeleteButton}                                  
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {(pages > 0 && scoops.length > 0) && <Pagination count={pages} page={page} onChange={handleChange} />}
            <Fab className={`${classes.root} ${classes[orientation]}`}>{loading ? <CircularProgress color="secondary" /> : <IconButton onClick={reload}>
                        <ReplayIcon fontSize="large" />
            </IconButton>}</Fab>
        </div>
    );
};

export default News;