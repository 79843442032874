import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import News from './News';
import { SocketIOProvider } from "use-socketio";
import { backend } from "./config";

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    return (
        <SocketIOProvider url={`${backend}`} opts={{ path: `/api/socket` }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <News />
            </ThemeProvider>
        </SocketIOProvider>
    );
}
export default App;